import React, { Component } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import SEO from '../components/seo'
import Nav from '../components/nav'
import Logo from '../components/logo'
// import CrownVideo from '../videos/crown.mp4'
import SkyFloorVideo from '../videos/skyfloor.mp4'
// import SkyLobbyVideo from '../videos/skylobby.mp4'
// import PodiumVideo from '../videos/podium.mp4'

class Explore extends Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
    this.state = {
      backgroundDark : true,
      currentTime: 0,
      title: ''
    }
  }

  onLeave(origin, destination, direction) {
    if (destination.index === 1 || destination.index === 2 || destination.index === 5 || destination.index === 9) {
      this.setState({ backgroundDark: false })
    }
    else {
      this.setState({ backgroundDark: true })
    }
  }

  render() {
    const data = this.props.data
    
    return (
      <>
        <SEO title="Inside Exchange 106" />
        <Nav overwrite="d-none" logoFill={`${this.state.backgroundDark ? 'dark' : 'light' }`}>
          <div className="col d-none d-md-block text-left">
            <Link className="fill-dark" to="/" style={{height:"1.5rem"}}><Logo /></Link>
          </div>
          <div className="col d-block d-md-none text-left">
            <Link className="fill-dark" to="/" style={{height:"0.725rem"}}><Logo /></Link>
          </div>
        </Nav>
        <ReactFullpage
          // fullpage options
          licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
          anchors={['inside', 'crown', 'skyfloor', 'superhighzone', 'highzone', 'skylobby', 'lowhighzone', 'midzone', 'lowzone', 'podium']}
          navigation
          slidesNavigation
          navigationTooltips={['Inside Exchange 106', 'The Crown', 'Sky Floor', 'Sky Zone 2', 'Sky Zone 1', 'Sky Lobby', 'High Zone', 'Mid Zone', 'Low Zone', 'Podium']}
          // sectionsColor={['#eee', '#002856', '#002856', '#eee', '#eee', '#002856', '#eee', '#eee', '#eee', '#002856']}
          lazyLoading
          onLeave={this.onLeave.bind(this)}

          render={() => (
            <ReactFullpage.Wrapper>
              <div id="splitpage">
                <section className="section" key="section0" id="section0">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      {/* <picture className="img-fluid h-100 w-100" style={{ objectFit:"cover" }}>
                        <source srcSet={data.allFile.edges[0].node.childImageSharp.fixed.srcWebp} type="image/webp" />
                        <source srcSet={data.allFile.edges[0].node.childImageSharp.fixed.src} type="image/jpeg" /> 
                        <img
                          className="img-fluid h-100 w-100"
                          src={data.allFile.edges[0].node.childImageSharp.fixed.src}
                          alt="Inside Exchange 106"
                          style={{ objectFit:"cover" }}
                          useMap="#explore"
                        />
                      </picture> */}
                      <Img
                        className="img-fluid h-100"
                        fixed={data.allFile.edges[0].node.childImageSharp.fixed}
                        alt="Inside Exchange 106"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center">
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title">
                          Inside Exchange 106
                        </h2>
                        <div className="py-3">
                          <p>The more you experience Exchange 106, the clearer the seamless transaction between business and pleasure, elegance and functionality becomes. The lobby sits at the core, offering direct and hassle-free access to 3,000 basement parking spaces, the Exchange Gallery that fronts the CBD loop; the MRT, in addition to two floors of exceptional retail and dining outlets.</p>
                          
                          <p className="mb-0">The lobby and retail floors, connected via large, stylish and naturally lit atriums are reached using escalators, giving a sense of continuous, relaxed movement, as well as a visual connection to all levels.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section1" id="section1">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[1].node.childImageSharp.fixed}
                        alt="The Crown"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center" style={{ backgroundImage: `url(${data.crownImage.childImageSharp.fluid.src})`, backgroundPosition:"top center", backgroundSize:"cover" }}>
                      {/* <video className="video" loop muted data-autoplay>
                        <source src={CrownVideo} type="video/mp4" />
                      </video> */}
                      {/* <Img
                        className="img-fluid h-100"
                        fixed={data.crownImage.childImageSharp.fixed}
                        alt="Inside Exchange 106"
                      /> */}
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title text-white">
                          The Crown
                        </h2>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section2" id="section2">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[2].node.childImageSharp.fixed}
                        alt="Sky Floor"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center">
                      <video className="video" loop muted data-autoplay>
                        <source src={SkyFloorVideo} type="video/mp4" />
                      </video>
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title text-white">
                          Sky Floor
                        </h2>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section3" id="section3">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[3].node.childImageSharp.fixed}
                        alt="Sky Zone 2"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center">
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title">
                          Sky Zone 2
                        </h2>
                        <div className="row py-3">
                          <div className="col-12">
                            <p>Level 78 - 106</p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zonePlanSuperHigh.childImageSharp.fluid}
                              alt="Sky Zone 2 Plan"
                            />
                            <p className="text-center">
                              Sky Zone 2 Plan (L78 - L106)<br/>
                              20,706 - 23,223 sqft
                            </p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zoneLayoutSuperHigh.childImageSharp.fluid}
                              alt="Sky Zone 2 Suggested Layout"
                            />
                            <p className="text-center">
                              Sky Zone 2 Suggested Layout<br/>
                              Total Capacity : 193 seats
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section4" id="section4">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[4].node.childImageSharp.fixed}
                        alt="Sky Zone 1"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center">
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title">
                          Sky Zone 1
                        </h2>
                        <div className="row py-3">
                          <div className="col-12">
                            <p>Level 56 - 77</p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zonePlanHigh.childImageSharp.fluid}
                              alt="Sky Zone 1 Plan"
                            />
                            <p className="text-center">
                              Sky Zone 1 Plan (L56 - L77)<br/>
                              23,423 - 26,509 sqft
                            </p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zoneLayoutHigh.childImageSharp.fluid}
                              alt="Sky Zone 1 Layout"
                            />
                            <p className="text-center">
                              Sky Zone 1 Suggested Layout<br/>
                              Total Capacity : 208 seats
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section5" id="section5">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[5].node.childImageSharp.fixed}
                        alt="Sky Lobby"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center" style={{ backgroundImage: `url(${data.skylobbyImage.childImageSharp.fluid.src})`, backgroundPosition:"top center", backgroundSize:"cover" }}>
                      {/* <video className="video" loop muted data-autoplay>
                        <source src={SkyLobbyVideo} type="video/mp4" />
                      </video> */}
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title text-white">
                          Sky Lobby
                        </h2>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section6" id="section6">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[6].node.childImageSharp.fixed}
                        alt="High Zone"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center">
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title">
                          High Zone
                        </h2>
                        <div className="row py-3">
                          <div className="col-12">
                            <p>Level 38 - 55</p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zonePlanLowHigh.childImageSharp.fluid}
                              alt="High Zone Plan"
                            />
                            <p className="text-center">
                              High Zone Plan (L38 - L55)<br/>
                              26,843 - 29,034 sqft
                            </p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zoneLayoutLowHigh.childImageSharp.fluid}
                              alt="High Zone Layout"
                            />
                            <p className="text-center">
                              High Zone Suggested Layout<br/>
                              Total Capacity : 178 seats
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section7" id="section7">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[7].node.childImageSharp.fixed}
                        alt="Mid Zone"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center">
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title">
                          Mid Zone
                        </h2>
                        <div className="row py-3">
                          <div className="col-12">
                            <p>Level 22 - 37</p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zonePlanMid.childImageSharp.fluid}
                              alt="Mid Zone Plan"
                            />
                            <p className="text-center">
                              Mid Zone Plan (L22 - L37)<br/>
                              28,581 - 31,103 sqft
                            </p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zoneLayoutMid.childImageSharp.fluid}
                              alt="Mid Zone Layout"
                            />
                            <p className="text-center">
                              Mid Zone Suggested Layout<br/>
                              Total Capacity : 182 seats
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section8" id="section8">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[8].node.childImageSharp.fixed}
                        alt="Low Zone"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center">
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title">
                          Low Zone
                        </h2>
                        <div className="row py-3">
                          <div className="col-12">
                            <p>Level 6 - 21</p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zonePlanLow.childImageSharp.fluid}
                              alt="Low Zone Plan"
                            />
                            <p className="text-center">
                              Low Zone Plan (L6 - L21)<br/>
                              31,045 - 34,154 sqft
                            </p>
                          </div>
                          <div className="col-md">
                            <Img
                              className="img-fluid mx-auto w-75"
                              fluid={data.zoneLayoutLow.childImageSharp.fluid}
                              alt="Low Zone Layout"
                            />
                            <p className="text-center">
                              Low Zone Suggested Layout<br/>
                              Total Capacity : 255 seats
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section" key="section9" id="section9">
                  <div className="d-flex flex-row h-100">
                    <div className="column column-left col-4 col-md-auto px-0 d-flex align-items-end pt-3 pt-md-5">
                      <Img
                        className="h-100"
                        fixed={data.allFile.edges[9].node.childImageSharp.fixed}
                        alt="Podium"
                        objectPosition="center top"
                      />
                    </div>
                    <div className="column column-right flex-md-fill col-md-auto d-flex align-items-center" style={{ backgroundImage: `url(${data.podiumImage.childImageSharp.fluid.src})`, backgroundPosition:"top center", backgroundSize:"cover" }}>
                      {/* <video className="video" loop muted data-autoplay ref={this.video} onTimeUpdate={() => {
                        const currentTime = this.video.current.currentTime

                        if (currentTime > 0 && currentTime < 4.79) {
                          this.setState({ title: 'Banking Hall' })
                        } else if (currentTime > 4.80 && currentTime < 9.89) {
                          this.setState({ title: 'Selected Retail' })
                        } else if (currentTime > 9.90 && currentTime < 13.68) {
                          this.setState({ title: 'Destination F&B' })
                        } else if (currentTime > 13.69 && currentTime < 18) {
                          this.setState({ title: 'Sky Bar' })
                        } else {
                          this.setState({ title: '  ' })
                        }
                      }}>
                        <source src={PodiumVideo} type="video/mp4" />
                      </video> */}
                      <div className="container py-2 pl-0 pr-4 p-lg-5">
                        <h2 className="title text-white mb-0">
                          Podium
                        </h2>
                        <p className="text-uppercase text-white mb-0">{this.state.title}&nbsp;</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              {/* <map name="explore">
                <area target="" alt="The Crown" title="The Crown" href="#crown" coords="135,66,169,46,213,60,223,144,166,133,126,147" shape="poly" />
                <area target="" alt=" Sky Floor" title=" Sky Floor" href="#skyfloor" coords="123,186,165,172,227,184,225,145,167,133,124,148" shape="poly" />
                <area target="" alt="Super High Zone" title="Super High Zone" href="#superhighzone" coords="120,303,165,297,231,302,228,184,165,172,123,186" shape="poly" />
                <area target="" alt="High Zone" title="High Zone" href="#highzone" coords="117,421,163,427,233,424,231,302,166,297,120,303" shape="poly" />
                <area target="" alt="Sky Lobby" title="Sky Lobby" href="#skylobby" coords="116,445,165,453,234,450,233,424,164,427,117,421" shape="poly" />
                <area target="" alt="Low High Zone" title="Low High Zone" href="#lowhighzone" coords="163,549,238,539,235,450,164,453,116,445,113,536" shape="poly" />
                <area target="" alt="Mid Zone" title="Mid Zone" href="#midzone" coords="162,646,241,629,238,539,164,549,112,536,110,623" shape="poly" />
                <area target="" alt="Low Zone" title="Low Zone" href="#lowzone" coords="242,721,241,629,163,646,111,624,108,712,162,745" shape="poly" />
                <area target="" alt="Podium" title="Podium" href="#podium" coords="164,794,184,807,289,819,289,770,269,770,269,753,243,722,162,746,108,713,106,751,144,776,162,779" shape="poly" />
              </map> */}

            </ReactFullpage.Wrapper>
          )}
        />
      </>
    )
  }
}

export default Explore

export const query = graphql`
  query {
    allFile(filter: {name: {regex: "/explore/"}}, sort: {fields: name}) {
      edges {
        node {
          id
          name
          ext
          publicURL
          childImageSharp {
            fixed(width: 355, height: 826, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    crownImage: file(relativePath: {eq: "crown.png"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    skylobbyImage: file(relativePath: {eq: "skylobby.png"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    podiumImage: file(relativePath: {eq: "podium.png"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    zonePlanSuperHigh: file(relativePath: {eq: "zone-plan-superhigh.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zoneLayoutSuperHigh: file(relativePath: {eq: "zone-layout-superhigh.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zonePlanHigh: file(relativePath: {eq: "zone-plan-high.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zoneLayoutHigh: file(relativePath: {eq: "zone-layout-high.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zonePlanLowHigh: file(relativePath: {eq: "zone-plan-lowhigh.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zoneLayoutLowHigh: file(relativePath: {eq: "zone-layout-lowhigh.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zonePlanMid: file(relativePath: {eq: "zone-plan-mid.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zoneLayoutMid: file(relativePath: {eq: "zone-layout-mid.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zonePlanLow: file(relativePath: {eq: "zone-plan-low.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zoneLayoutLow: file(relativePath: {eq: "zone-layout-low.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`